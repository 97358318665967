/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";

import { H2 } from "./text.js";

import headerBgImg from "../images/header-bg.png";
import tkkgSm from "../images/tkkg-badge-sm.png";
import tkkgSm2x from "../images/tkkg-badge-sm@2x.png";
import tkkg from "../images/tkkg-badge.png";
import tkkg2x from "../images/tkkg-badge@2x.png";

import { Link } from "gatsby";

const Background = withTheme(({ theme }) => (
  <div
    css={css`
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    `}
  >
    <div
      css={css`
        position: absolute;
        left: -10%;
        top: -20%;
        width: 120%;
        height: 120%;
        background: ${theme.color["spot1-light"]} url(${headerBgImg}) -20% bottom
          no-repeat;
        background-size: 174%;
        border-bottom: solid 6px ${theme.color.text};
        transform-origin: center bottom;
        transform: rotate(-2.5deg);
        box-shadow: 0 2px 20px 0 rgba(57, 84, 163, 0.2);

        ${theme.funcs.mediaquery(
          "xl",
          `
            transform: rotate(-1deg);
            background-size: auto;
            background-position: center bottom;
        `
        )}
      `}
    ></div>
    {/* TODO: add star glow */}
  </div>
));

const Badge = (props) => (
  <div
    css={css`
      position: relative;
      background: url(${tkkgSm}) left top no-repeat;
      display: block;
      width: 132px;
      height: 132px;
      margin: 0 -15px -15px;

      @media screen and (-webkit-min-device-pixel-ratio: 2),
        screen and (min-resolution: 192dpi),
        screen and (min-resolution: 2dppx) {
        background-image: url(${tkkgSm2x});
        background-size: 132px 132px;
      }
      @media screen and (min-width: 768px),
        screen and (min-width: 768px),
        screen and (min-width: 768px) {
        background-image: url(${tkkg});
        width: 215px;
        height: 212px;
        margin: 0 -28px -28px;
      }
      @media screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 768px),
        screen and (min-resolution: 192dpi) and (min-width: 768px),
        screen and (min-resolution: 2dppx) and (min-width: 768px) {
        background-image: url(${tkkg2x});
        background-size: 215px 212px;
      }
    `}
    {...props}
  ></div>
);

const AppHeader = withTheme(({ theme, ...props }) => {
  return (
    <div
      css={css`
        position: relative;
        height: 95px;
        ${theme.funcs.mediaquery(
          "xl",
          `
          height: 145px;
      `
        )}
      `}
      {...props}
    >
      <Background />
      <div
        css={css`
          position: relative;
          display: flex;
          box-sizing: content-box;
          margin: 0 auto;
          padding: 0 22px;
          max-width: 870px;
          height: 100%;
          align-items: center;
        `}
      >
        <Link to="/">
          <Badge
            css={css`
              position: absolute;
              left: 22px;
              top: 0;
              display: inline-block;
            `}
          />
        </Link>
        <H2
          as="h1"
          css={css`
            text-transform: uppercase;
            padding-left: 115px;
            ${theme.funcs.mediaquery(
              "xl",
              `
          padding-left: 195px;
      `
            )}
          `}
        >
          {" "}
          Meisterdetektiv-Programm
        </H2>
      </div>
    </div>
  );
});

export default AppHeader;
