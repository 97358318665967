import React, { useState } from "react";
import Link, { navigate } from "gatsby-link";

import LoginForm from "./LoginForm";
import ModalPortal from "./ModalPortal";
import Veil from "./Veil";
import { getAchievement } from "../services/achievements";

const AchievementLink = ({ to, children, message, ...props }) => {
  const [showLogin, setShowLogin] = useState();

  const tryToNavigate = (e) => {
    if (e) {
      e.preventDefault();
    }
    setShowLogin(true);
  };
  const handleClose = () => {
    setShowLogin(false);
  };
  const handleSuccess = (achievement) => {
    // handleClose();
    navigate(to, { state: { achievement } });
  };
  return (
    <>
      <Link to={to} onClick={tryToNavigate} {...props}>
        {children}
      </Link>
      {!!showLogin && (
        <ModalPortal key="ModalPortal">
          <Veil />
          <LoginForm
            scope={to}
            onClose={handleClose}
            onSuccess={handleSuccess}
            validate={getAchievement}
            message={message}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default AchievementLink;
