import { encrypt } from "./auth";
import { achievements } from "../config";

const achievementsByPassword = achievements.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.password]: cur,
  }),
  {}
);

export const getAchievement = ({ password }) => {
  const achievement = achievementsByPassword[encrypt(password.toLowerCase())];
  if (achievement) {
    return achievement;
  }
  return false;
};
