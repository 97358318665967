import React, { useState } from "react";
import Link, { navigate } from "gatsby-link";

import LoginForm from "./LoginForm";
import ModalPortal from "./ModalPortal";
import Veil from "./Veil";
import { isAuthorized } from "../services/auth";

const ProtectedLink = ({ to, children, message, ...props }) => {
  const [showLogin, setShowLogin] = useState();

  const tryToNavigate = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (isAuthorized(to)) {
      navigate(to);
    } else {
      setShowLogin(true);
    }
  };
  const handleClose = () => {
    setShowLogin(false);
  };
  const handleSuccess = () => {
    // handleClose();
    tryToNavigate();
  };
  return (
    <>
      <Link to={to} onClick={tryToNavigate} {...props}>
        {children}
      </Link>
      {!!showLogin && (
        <ModalPortal>
          <Veil />
          <LoginForm
            key="loginForm"
            scope={to}
            message={message}
            onClose={handleClose}
            onSuccess={handleSuccess}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default ProtectedLink;
